
import * as ipxRuntime$HMsjf1blaQX7Rl1hkkH51myxtXXV9aX36QTfUmOto3Y from '/opt/buildhome/repo/node_modules/.pnpm/@nuxt+image@1.10.0_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/providers/ipx'
import * as ipxStaticRuntime$YZJYwu_452VsGSneBlxX_a98dE35970njXqEvmNJyIc8k from '/opt/buildhome/repo/node_modules/.pnpm/@nuxt+image@1.10.0_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/providers/ipxStatic'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipxStatic",
  "domains": [
    "cms-assets.americanbookcompany.com"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$HMsjf1blaQX7Rl1hkkH51myxtXXV9aX36QTfUmOto3Y, defaults: {} },
  ['ipxStatic']: { provider: ipxStaticRuntime$YZJYwu_452VsGSneBlxX_a98dE35970njXqEvmNJyIc8k, defaults: {} }
}
        